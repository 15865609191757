<template>
  <Flex v-bind="props">
    <slot />
  </Flex>
</template>

<script setup lang="ts">
import type { Props } from './Flex.vue'

const props = defineProps<Props>()
</script>z
